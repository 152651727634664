onComplete(function () {
  var process = function (liquidContent) {
    if (!liquidContent) {
      return;
    }

    // Convert data into regular expression and lookup table for replacing.
    var dataLookup = {};
    var dataKeys = Object.keys(liquidContent);
    var regularExpression = '';
    for (var i = 0; i < dataKeys.length; i++) {
      var dataKey = dataKeys[i];
      dataLookup['{%leadinfo.' + dataKey + '%}'] = liquidContent[dataKey];
      regularExpression += '{%leadinfo\\.' + dataKey + '%}|';
    }
    regularExpression = regularExpression.substring(0, regularExpression.length - 1);
    regularExpression = new RegExp(regularExpression, 'gi');
    var replaceInString = function (value, htmlEscape = false) {
      return value.replace(regularExpression, function (matched) {
        var text = dataLookup[matched];
        if (htmlEscape) {
          text = text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
        }
        return text;
      });
    };
    var processElement = function (wrapperElement) {
      // Process attributes.
      var processAttributeElement = function (element) {
        // Parse attribute into attributes.
        var attributeData = element.getAttribute('data-leadinfo-attributes');
        attributeData = attributeData.split(',');
        for (var i = 0; i < attributeData.length; i++) {
          if (!attributeData[i]) {
            continue;
          }
          var attributeValue = attributeData[i].split('=');
          var attributeName = attributeValue.shift();
          if (attributeValue.length > 0) {
            attributeValue = attributeValue.join('=');
            // Process attribute value.
            attributeValue = replaceInString(attributeValue);
          } else {
            attributeValue = true;
          }

          // Set attribute on element.
          element.setAttribute(attributeName, attributeValue);
        }
      };
      attributeElements = wrapperElement.querySelectorAll('[data-leadinfo-attributes]');
      for (var i = 0; i < attributeElements.length; i++) {
        processAttributeElement(attributeElements[i]);
      }

      // Process inner html.
      var processHtmlElement = function (element) {
        var format = element.getAttribute('data-leadinfo-html');
        element.innerHTML = replaceInString(format, true);
      };
      var htmlElements = wrapperElement.querySelectorAll('[data-leadinfo-html]');
      for (var i = 0; i < htmlElements.length; i++) {
        processHtmlElement(htmlElements[i]);
      }

      // Process text.
      var processTextElement = function (element) {
        var format = element.getAttribute('data-leadinfo-text');
        element.innerText = replaceInString(format);
      };
      var textElements = wrapperElement.querySelectorAll('[data-leadinfo-text]');
      for (var i = 0; i < textElements.length; i++) {
        processTextElement(textElements[i]);
      }
    };

    // Scan the dom for root elements.
    var wrapperElements = document.body.querySelectorAll('[data-leadinfo-replace-container]');
    for (var i = 0; i < wrapperElements.length; i++) {
      processElement(wrapperElements[i]);
    }
  };

  // Invoke when available.
  window.NCLeadinfo.onLiquidContent(process);

  // Re-invoke when switching into idle mode.
  if (window.editor) {
    window.editor.addEventListener('reloaded', function () {
      if (window.editor.getMode() === window.Editor.MODES.idle) {
        window.NCLeadinfo.onLiquidContent(process);
      }
    });
  }
});